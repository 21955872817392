import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import config from "./configReducer"
import LoginReducer from "./loginReducer"
import VehicleReducer from "./vehicleReducer"
import ThemeOptions from "./ThemeOptions"
import usersReducer from "./usersReducer"
import deviceType from "./deviceReducer"
import alertNotificationReducer from "./alertNotificationReducer"

const persistConfigLogin = {
  key: "root",
  storage,
  whitelist: ["pagesAuth"]
}

export default combineReducers({
  ThemeOptions,
  userProfile: persistReducer(persistConfigLogin, LoginReducer),
  vehicles: VehicleReducer,
  users: usersReducer,
  devices: deviceType,
  alertNotification: alertNotificationReducer,
  config
  // usersId: usersIdReducer
})
