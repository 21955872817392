import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { persistStore } from "redux-persist"

import rootReducer from "../reducers"

const initialState = {}

const middleware = [thunk]

export const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware)
    // ,
    // process.env.NODE_ENV === "development" &&
    //   window.__REDUX_DEVTOOLS_EXTENSION__ &&
    //   window.__REDUX_DEVTOOLS_EXTENSION__()
  )
)
export const persistor = persistStore(store)
