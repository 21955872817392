import React, { Suspense } from "react"
import { Route } from "react-router-dom"
import Loader from "react-loaders"

const fallback = (
  <div className="loader-container">
    <div className="loader-container-inner">
      <div className="text-center">
        <Loader type="ball-grid-beat" />
      </div>
      <h6 className="mt-3">Nucleus Microsystem Pvt Ltd.</h6>
    </div>
  </div>
)

export default ({ path, component }) => {
  // console.log("Path: ", path)
  return (
    <Suspense fallback={fallback}>
      <Route path={path} component={component} />
    </Suspense>
  )
}
