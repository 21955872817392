import React, { memo, useState } from "react"
import styled, { keyframes, css } from "styled-components"
import { useTranslation } from "react-i18next"
import Icons from "../../../../DemoPages/Icons/Examples/icons"
import CloseX from "../../../../DemoPages/MyComponents/closeX"
import Profile from "./components/profile"
import ServiceProvider from "./components/serviceProvider"
import Feedback from "./components/feedback"
import gps from "../../../../assets/images/gps.png"

const zoomIn = keyframes`
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
`

const flex_center_center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Footer = styled.div`
  p {
    margin-bottom: 0 !important;
    text-align: right;
    background-color: rgb(248, 249, 250);
  }
  p:last-child {
    padding-top: 0;
  }
`

const Ul = styled.div`
  li {
    display: flex;
    padding: 10px 20px;
    list-style: none;
    cursor: pointer;
    opacity: 0.8;
    p {
      margin-left: 10px;
      margin-bottom: 0 !important;
    }
    :hover {
      opacity: 1;
      background: rgb(224, 243, 255);
      p {
        color: var(--primary);
      }
    }
  }
`

const Modal = styled.div`
  background: rgba(0, 0, 0, 0.15);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  ${flex_center_center};

  > div {
    border-radius: 4px;
    height: ${props => props.height && props.height};
    max-height: 80vh;
    animation: ${zoomIn} 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
`

const Container = styled.div`
  width: 30vw;
  padding: 10px 20px;
  background: #fff;
  z-index: 10;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
    0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  position: relative;
  > div:first-child {
    position: absolute;
    right: 0;
    top: 1rem;
  }
`

const CloseWrapper = styled.div`
  > div {
    margin-right: 1rem;
  }
`

const LogoHeader = styled.div`
  width: 100%;
  background: darkcyan;
  padding: 16px 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
`

const LogoWrapper = styled.div`
  // margin-right: 1.6rem;
  img {
    width: 40px;
  }
`
const H5 = styled.h5`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  margin: 0;
`
export const MainNav = [
  //   {
  //     icon: "pe-7s-rocket",
  //     label: "Dashboards",
  //     to: "/dashboard"
  //   },
  {
    icon: "userCircle",
    fill: "var(--success)",
    label: "PROFILE",
    value: "profile"
  },
  {
    icon: "building",
    fill: "var(--warning)",
    label: "SERVICE_PROVIDER",
    value: "service"
  },
  {
    icon: "message",
    fill: "var(--info)",
    label: "FEEDBACK",
    value: "feedback"
  },
  {
    icon: "building",
    fill: "var(--teal)",
    label: "ABOUT_US",
    value: "aboutus"
  }
]

const DrawerBigExample = () => {
  const [activeModal, setActiveModal] = useState({})
  const { t } = useTranslation()

  const renderUserdHeader = () => {
    const userName = localStorage.getItem("user_name")
      ? localStorage.getItem("user_name")
      : localStorage.getItem("email")
      ? localStorage.getItem("email")
      : null
    const idx = userName.indexOf("@")
    const user = userName.slice(0, idx)
    return (
      <LogoHeader>
        {/* <CardHeader
          tag="h1"
          className="justify-content-between drawer-heading text-success pr-4"
        > */}
        <LogoWrapper>
          <img src={gps} alt="gps.png" />
        </LogoWrapper>
        <H5>{user}</H5>
        {/* </CardHeader> */}
      </LogoHeader>
    )
  }

  const handleCloseModel = () => setActiveModal({ activeModal: {} })

  return (
    <Wrapper>
      <div>
        {renderUserdHeader()}
        <div className="app-sidebar__inner">
          <Ul>
            {MainNav.map(option => (
              <li
                key={option.value}
                onClick={() => setActiveModal({ [option.value]: true })}
              >
                <Icons icon={option.icon} fill={option.fill} />
                <p className={activeModal[option.value] && "text-primary"}>
                  {t(option.label)}
                </p>
              </li>
            ))}
          </Ul>
        </div>
      </div>
      <Footer>
        <p className="pr-4 pt-2">version 1.0</p>
        <p className="pr-4 py-2">copyright &copy; 2020</p>
      </Footer>
      {activeModal.profile && (
        <Modal height="480px">
          <Container>
            <CloseWrapper>
              <CloseX onClick={handleCloseModel} />
            </CloseWrapper>
            <Profile t={t} />
          </Container>
        </Modal>
      )}
      {activeModal.service && (
        <Modal height="480px">
          <Container>
            <CloseWrapper>
              <CloseX onClick={handleCloseModel} />
            </CloseWrapper>
            <ServiceProvider t={t} />
          </Container>
        </Modal>
      )}

      {activeModal.feedback && (
        <Modal height="480px">
          <Container>
            <CloseWrapper>
              <CloseX onClick={handleCloseModel} />
            </CloseWrapper>
            <Feedback t={t} onClose={handleCloseModel} />
          </Container>
        </Modal>
      )}
    </Wrapper>
  )
}

const MemoDrawerBigExample = memo(() => DrawerBigExample())
export default MemoDrawerBigExample
