import React from "react"
import { Redirect } from "react-router-dom"
import LazyRoute from "./lazyRoute"
// import AppHeader from "../AppHeader"
import AppFooter from "../AppFooter"

export default function PrivateRoute(props) {
  if (localStorage.getItem("key"))
    return (
      <>
        {/* <AppHeader alertNotification={props.alertNotification} /> */}
        <LazyRoute {...props} />
        <AppFooter />
      </>
    )
  return <Redirect to="/" />
}
