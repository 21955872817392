import React, { useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import styled from "styled-components"
import swal from "sweetalert"
import { useTranslation } from "react-i18next"
// import { connect } from "react-redux"
// import updateReportAction from "../../../actions/reportAction"
import pagesId from "../../../utils/pagesId"
import LanguagePopOver from "./LanguagePopOver"
import ReportsPopOver from "./ReportsPopOver"
import SettingsPopOver from "./SettingsPopOver"
import billing_0 from "../../../assets/svgs/billing_0.svg"
import billing_1 from "../../../assets/svgs/billing_1.svg"
import home_0 from "../../../assets/svgs/home_0.svg"
import home_1 from "../../../assets/svgs/home_1.svg"
import languages_0 from "../../../assets/svgs/languages_0.svg"
import languages_1 from "../../../assets/svgs/languages_1.svg"
import reports_0 from "../../../assets/svgs/reports_0.svg"
import reports_1 from "../../../assets/svgs/reports_1.svg"
import settings_0 from "../../../assets/svgs/settings_0.svg"
import settings_1 from "../../../assets/svgs/settings_1.svg"
import vehicle_0 from "../../../assets/svgs/vehicle_0.svg"
import vehicle_1 from "../../../assets/svgs/vehicle_1.svg"
import geofence_0 from "../../../assets/svgs/geofence_0.svg"
import geofence_1 from "../../../assets/svgs/geofence_1.svg"
import logout_0 from "../../../assets/svgs/logout_0.svg"
import users_1 from "../../../assets/svgs/users_1.svg"
import users_0 from "../../../assets/svgs/users_0.svg"
import addGeofence0 from "../../../assets/svgs/addGeofence0.svg"
import addGeofence1 from "../../../assets/svgs/addGeofence1.svg"
// import logout_0 from "../../../assets/svgs/logout_0.svg"

const Button = styled.button`
  // background: ${props => props.active && "rgba(0, 0, 0, 0.15) !important"};
  border-bottom: ${props =>
    props.active
      ? "4px solid var(--primary) !important"
      : "4px solid #f1f4f6 !important"};
  
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`

const IconWrapper = styled.div`
  img {
    width: 28px;
  }
`

const FooterDots = ({ history, location, match, pagesAuth }) => {
  const [popoverOpen1, setPopoverOpen1] = useState(false)
  const [popoverOpen2, setPopoverOpen2] = useState(false)
  const [popoverOpenSetting, setPopoverOpenSetting] = useState(false)
  const [isActive, setIsActive] = useState({})
  const { t } = useTranslation()

  useEffect(() => {
    let isCancel = false
    const activeTab = JSON.parse(localStorage.getItem("activeTab"))
    !isCancel && setIsActive(activeTab)
    return () => {
      isCancel = true
    }
  }, [])

  const saveToStorage = useCallback(() => {
    const saveTab = JSON.stringify(isActive)
    localStorage.setItem("activeTab", saveTab)
  })

  useEffect(() => {
    window.addEventListener("beforeunload", saveToStorage)
    return () => {
      window.removeEventListener("beforeunload", saveToStorage)
    }
  }, [saveToStorage])

  const toggle1 = e => setPopoverOpen1(!popoverOpen1)
  const toggle2 = e => setPopoverOpen2(!popoverOpen2)

  const toggleSetting = e => setPopoverOpenSetting(!popoverOpenSetting)

  const renderButton = (src, label, value, callback, id = null) => (
    <Button
      id={id}
      className="dot-btn-wrapper"
      active={isActive[value]}
      onClick={callback}
    >
      <IconWrapper active={isActive[label.toLowerCase()]}>
        <img src={src} alt={src} />
      </IconWrapper>
      <p className={`${isActive[value] && "text-primary"} mb-0`}>{label}</p>
    </Button>
  )

  const handleRedirectClick = reportType => {
    setPopoverOpen2(!popoverOpen2)
    !isActive.reports && setIsActive({ reports: !isActive.reports })
    // updateReportAction(reportType)
    history.push(`/reports/${reportType.replace(/\s/g, "_")}`)
  }

  const handleRedirectSettings = reportType => {
    setPopoverOpenSetting(!popoverOpenSetting)
    !isActive.settings &&
      setIsActive({
        settings: !isActive.settings
      })
    // updateReportAction(reportType)
    history.push(`/setting/${reportType.replace(/\s/g, "_")}`)
  }

  return (
    <>
      {renderButton(isActive.home ? home_1 : home_0, t("HOME"), "home", () => {
        !isActive.home && setIsActive({ home: !isActive.home })
        history.push("/dashboard")
      })}
      <div className="dots-separator" />
      {pagesAuth["17"] &&
        renderButton(
          isActive.vehicles ? vehicle_1 : vehicle_0,
          t("VEHICLES"),
          "vehicles",
          () => {
            !isActive.vehicles && setIsActive({ vehicles: !isActive.vehicles })
            history.push("/vehicles")
          }
        )}
      <div className="dots-separator" />
      {pagesAuth["16"] &&
        renderButton(
          isActive.users ? users_1 : users_0,
          t("USERS"),
          "users",
          () => {
            !isActive.users && setIsActive({ users: !isActive.users })
            history.push("/users")
          }
        )}
      <div className="dots-separator" />
      {pagesAuth["415"] &&
        renderButton(
          isActive.geofence ? addGeofence1 : addGeofence0,
          t("GEOFENCE"),
          "geofence",
          () => {
            !isActive.geofence && setIsActive({ geofence: !isActive.geofence })
            history.push("/geofence")
          }
        )}
      <div className="dots-separator" />
      {/* {renderButton(languages_0, t("LANGUAGE"), toggle1, "PopoverFooter-1")}
      <div className="dots-separator" /> */}
      {pagesAuth["182"] &&
        renderButton(
          isActive.billing ? billing_1 : billing_0,
          t("BILLING"),
          "billing",
          () => {
            !isActive.billing && setIsActive({ billing: !isActive.billing })
            history.push("/billing")
          }
        )}

      <div className="dots-separator" />
      {// pagesAuth["3"] &&
      renderButton(
        isActive.reports ? reports_1 : reports_0,
        t("REPORTS"),
        "reports",
        toggle2,
        "PopoverFooter-2"
      )}
      {/* {renderButton(languages_0, "Language", toggle1, "PopoverFooter-2")} */}
      <div className="dots-separator" />
      {// pagesAuth["4"] &&
      renderButton(
        isActive.settings ? settings_1 : settings_0,
        t("SETTINGS"),
        "settings",
        toggleSetting,
        "popoverOpenSetting"
      )}
      {/* {renderButton(languages_0, "Language", toggle1, "PopoverFooter-2")} */}
      <div className="dots-separator" />
      {renderButton(
        isActive.logOut ? logout_0 : logout_0,
        t("LOGOUT"),
        "logOut",
        () => {
          setIsActive({ logOut: !isActive.logOut })
          try {
            localStorage.clear()
            // window.location.reload()
            window.location.replace("/")
          } catch (ex) {
            console.log(ex)
            swal(t("UNEXPECTED_ERROR"))
          }
          // history.push("/billing")
        }
      )}
      {/* <LanguagePopOver toggle={toggle1} popoverOpen={popoverOpen1} /> */}
      <ReportsPopOver
        t={t}
        pagesAuth={pagesAuth}
        toggle={toggle2}
        popoverOpen={popoverOpen2}
        onRedirectClick={handleRedirectClick}
      />
      {/* Must set target in --PopOver to id of PopUp */}
      <SettingsPopOver
        t={t}
        pagesAuth={pagesAuth}
        toggle={toggleSetting}
        popoverOpen={popoverOpenSetting}
        onRedirectClick={handleRedirectSettings}
      />
    </>
  )
}

const isEqual = (prevProps, nextProps) =>
  !(
    Object.keys(prevProps.pagesAuth).length !==
    Object.keys(nextProps.pagesAuth).length
  )

const MemoFooterDots = React.memo(FooterDots, isEqual)

// export default withRouter(FooterDots)
// const mapStateToProps = (state, props) => {}

const mapStateToProps = state => ({
  pagesAuth: state.userProfile.pagesAuth
})

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(MemoFooterDots)
)
