export const FETCH_VEHICLE = "FETCH_VEHICLE"

export const FETCH_VEHICLE_HISTORY = "FETCH_VEHICLE_HISTORY"

export const FETCH_VEHICLE_TRIP = "FETCH_VEHICLE_TRIP"

export const FETCH_VEHICLE_DISTANCE = "FETCH_VEHICLE_DISTANCE"

export const CLEAR_VEHICLE_HISTORY = "CLEAR_VEHICLE_HISTORY"

export const CLEAR_VEHICLE_TRIP = "CLEAR_VEHICLE_TRIP"

export const CLEAR_VEHICLE_DISTANCE = "CLEAR_VEHICLE_DISTANCE"

export const FETCH_USERS = "FETCH_USERS"

export const UPDATE_USERS_ID = "UPDATE_USERS_ID"

export const FETCH_VEHICLE_ALERTS = "FETCH_VEHICLE_ALERTS"

export const CLEAR_VEHICLE_ALERTS = "CLEAR_VEHICLE_ALERTS"

export const FETCH_VEHICLE_FLEET = "FETCH_VEHICLE_FLEET"

export const CLEAR_VEHICLE_FLEET = "CLEAR_VEHICLE_FLEET"

export const FETCH_VEHICLE_OVER_SPEED = "FETCH_VEHICLE_OVER_SPEED"

export const CLEAR_VEHICLE_OVER_SPEED = "CLEAR_VEHICLE_OVER_SPEED"

export const FETCH_VEHICLE_FUEL = "FETCH_VEHICLE_FUEL"

export const CLEAR_VEHICLE_FUEL = "CLEAR_VEHICLE_FUEL"

export const FETCH_VEHICLE_TEMP = "FETCH_VEHICLE_TEMP"

export const CLEAR_VEHICLE_TEMP = "CLEAR_VEHICLE_TEMP"

export const FETCH_VEHICLE_TRIP_IGNITION = "FETCH_VEHICLE_TRIP_IGNITION"

export const CLEAR_VEHICLE_TRIP_IGNITION = "CLEAR_VEHICLE_TRIP_IGNITION"

export const FETCH_VEHICLE_DIGITAL_SENSOR = "FETCH_VEHICLE_DIGITAL_SENSOR"

export const CLEAR_VEHICLE_DIGITAL_SENSOR = "CLEAR_VEHICLE_DIGITAL_SENSOR"

export const FETCH_DEVICE_TYPE = "FETCH_DEVICE_TYPE"

export const FETCH_VEHICLE_TYPE = "FETCH_VEHICLE_TYPE"

export const FETCH_VEHICLE_BRAND = "FETCH_VEHICLE_BRAND"

export const FETCH_VEHICLE_MAKE = "FETCH_VEHICLE_MAKE"

export const FETCH_ALERT_NOTIFICATION = "FETCH_ALERT_NOTIFICATION"
