const devBaseUrl = "https://track.georadius.in/"
const devDomainName = "track.georadius.in"
// const devBaseUrl = "https://track.gpsplatform.in/"
// const devDomainName = "track.gpsplatform.in"

const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://track.gpsplatform.in/"
    : devBaseUrl

// ? `${window.location.origin}/`
// "http://10.1.30.183/"
const domainName =
  process.env.NODE_ENV === "production"
    ? "track.gpsplatform.in/"
    : devDomainName

// ? `${window.location.host}/`

const mapDefUrl = "http://map.nms.co.in/osm_tiles/{z}/{x}/{y}.png"

export default {
  baseUrl,
  domainName,
  mapDefUrl,
  loginAction: "login"
}
