import React from "react"
import styled from "styled-components"
import Icons from "../../../../../DemoPages/Icons/Examples/icons"

const Wrapper = styled.div`
  width: 100%;
  padding-top: 2rem;
`

const Card = styled.div`
  padding: 10px 20px;
  display: flex;
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  margin-top: 1.6rem;
  p {
    margin-left: 10px;
    margin-bottom: 0;
  }
`

export default ({ t }) => {
  return (
    <Wrapper>
      <h4 className="text-center">{t("SERVICE_PROVIDER")}</h4>
      <Card>
        <Icons icon="building" fill="var(--primary)" />
        <p>apps@nms.co.in</p>
      </Card>
      <Card>
        <Icons icon="user" fill="var(--success)" />
        <p>NMS-ALL</p>
      </Card>
      <Card>
        <Icons icon="phone" fill="var(--red)" />
        <p>+91 8123452672</p>
      </Card>
      <Card>
        <Icons icon="email" fill="var(--cyan)" />
        <p>info@nms.co.in</p>
      </Card>
    </Wrapper>
  )
}
