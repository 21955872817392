import http from "./httpService"
import axios from "axios"
import baseConfig from "../config/defaultConfig"

export const sendFeedback = formData =>
  axios({
    method: "post",
    url: `${baseConfig.baseUrl}feedback.php`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" }
  })
//   http.post("feedback.php", {
//     app_name: "gps_platform",
//     action: "feedback_submit",
//     issue_type,
//     issue_desc,
//     user_id
//   })
