import { LOGIN, PAGES_AUTH } from "../types/Authentication"

const initialLoginStore = {
  user_name: "",
  email: "",
  user_id: null,
  pagesAuth: {},
  functionAuth: {}
}
// const initialRegisterStore = {}

export default (state = initialLoginStore, action) => {
  // console.log("reducer: ", action.payload)
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        ...action.payload
      }

    default:
      return {
        ...state
      }
  }
}
