import React from "react"
import styled from "styled-components"
import loaderSvg from "../../assets/svgs/loader2.svg"
import Loader from "react-loaders"

const LoaderWrapper = styled.div`
  background: rgba(0, 0, 0, 0.15);
  // background: rgba(241, 242, 243, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  img {
    height: 60px;
  }
`

export default () => (
  <LoaderWrapper>
    <Loader type="ball-grid-beat" />
    {/* <img src={loaderSvg} alt="loader.svg" /> */}
  </LoaderWrapper>
)
