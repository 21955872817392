import {
  FETCH_VEHICLE,
  FETCH_VEHICLE_HISTORY,
  CLEAR_VEHICLE_HISTORY,
  FETCH_VEHICLE_TRIP,
  CLEAR_VEHICLE_TRIP,
  FETCH_VEHICLE_DISTANCE,
  CLEAR_VEHICLE_DISTANCE,
  FETCH_VEHICLE_ALERTS,
  CLEAR_VEHICLE_ALERTS,
  FETCH_VEHICLE_FLEET,
  CLEAR_VEHICLE_FLEET,
  FETCH_VEHICLE_OVER_SPEED,
  CLEAR_VEHICLE_OVER_SPEED,
  FETCH_VEHICLE_FUEL,
  CLEAR_VEHICLE_FUEL,
  FETCH_VEHICLE_TRIP_IGNITION,
  CLEAR_VEHICLE_TRIP_IGNITION,
  FETCH_VEHICLE_DIGITAL_SENSOR,
  CLEAR_VEHICLE_DIGITAL_SENSOR,
  FETCH_VEHICLE_TYPE,
  FETCH_VEHICLE_BRAND,
  FETCH_VEHICLE_MAKE,
  FETCH_ALERT_NOTIFICATION
} from "../types/types"

const initialState = {
  vehicleType: [],
  vehicleBrand: [],
  vehicleMake: []
  // alertNotification: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VEHICLE:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_VEHICLE_HISTORY:
      return {
        ...state,
        vehicleHistory: action.payload
      }
    case CLEAR_VEHICLE_HISTORY:
      return {
        ...state,
        vehicleHistory: null
      }
    case FETCH_VEHICLE_TRIP:
      return {
        ...state,
        vehicleTrip: action.payload
      }
    case CLEAR_VEHICLE_TRIP:
      return {
        ...state,
        vehicleTrip: null
      }
    case FETCH_VEHICLE_DISTANCE:
      return {
        ...state,
        vehicleDistance: action.payload
      }
    case CLEAR_VEHICLE_DISTANCE:
      return {
        ...state,
        vehicleDistance: null
      }
    case FETCH_VEHICLE_ALERTS:
      return {
        ...state,
        vehicleAlerts: action.payload
      }
    case CLEAR_VEHICLE_ALERTS:
      return {
        ...state,
        vehicleAlerts: null
      }
    case FETCH_VEHICLE_FLEET:
      return {
        ...state,
        vehicleFleet: action.payload
      }
    case CLEAR_VEHICLE_FLEET:
      return {
        ...state,
        vehicleFleet: null
      }
    case FETCH_VEHICLE_OVER_SPEED:
      return {
        ...state,
        vehicleOverSpeed: action.payload
      }
    case CLEAR_VEHICLE_OVER_SPEED:
      return {
        ...state,
        vehicleOverSpeed: null
      }
    case FETCH_VEHICLE_FUEL:
      return {
        ...state,
        vehicleFuel: action.payload
      }
    case CLEAR_VEHICLE_FUEL:
      return {
        ...state,
        vehicleFuel: null
      }
    case FETCH_VEHICLE_TRIP_IGNITION:
      return {
        ...state,
        vehicleTripIgnition: action.payload
      }
    case CLEAR_VEHICLE_TRIP_IGNITION:
      return {
        ...state,
        vehicleTripIgnition: null
      }
    case FETCH_VEHICLE_DIGITAL_SENSOR:
      return {
        ...state,
        vehicleDigitalSensor: action.payload
      }
    case CLEAR_VEHICLE_DIGITAL_SENSOR:
      return {
        ...state,
        vehicleDigitalSensor: null
      }
    case FETCH_VEHICLE_TYPE:
      return {
        ...state,
        vehicleType: action.payload
      }
    case FETCH_VEHICLE_BRAND:
      return {
        ...state,
        vehicleBrand: action.payload
      }
    case FETCH_VEHICLE_MAKE:
      return {
        ...state,
        vehicleMake: action.payload
      }
    // case FETCH_ALERT_NOTIFICATION:
    //   return {
    //     ...state,
    //     alertNotification: action.payload
    //   }
    default:
      return {
        ...state
      }
  }
}
