import React, { Suspense } from "react"
import styled from "styled-components"
import Hamburger from "react-hamburgers"
import PerfectScrollbar from "react-perfect-scrollbar"
import Drawer from "react-motion-drawer"
import LeftDrawer from "./TabsContent/leftDrawer"
// import loaderSvg from "../../../assets/svgs/loader1.svg"
import Loader from "react-loaders"

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 60px;
  }
`

const MyLoader = (
  <LoaderWrapper>
    {/* <img src={loaderSvg} alt="loader.png" /> */}
    <Loader type="ball-grid-beat" />
  </LoaderWrapper>
)

const LeftSideBar = ({ openRight, openLeft, onChangeOpenLeft }) => (
  <Suspense fallback={<>...</>}>
    <Drawer
      // right
      className="drawer-content-wrapper p-0"
      width={260}
      // width={450}
      open={openLeft}
      onChange={open => onChangeOpenLeft(open)}
      noTouchOpen={false}
      noTouchClose={false}
    >
      <PerfectScrollbar>
        <div className="justify-content-between drawer-nav-btn drawer-nav-btn_left">
          <Hamburger
            active={openLeft}
            type="elastic"
            onClick={() => onChangeOpenLeft(false, false)}
          />
        </div>
        <LeftDrawer />
      </PerfectScrollbar>
    </Drawer>
  </Suspense>
)

export default LeftSideBar
