import React from "react"
import { Provider } from "react-redux"
import { store, persistor } from "./config/configureStore"
import { PersistGate } from "redux-persist/integration/react"
// import Main from "./DemoPages/Main"
import AppMain from "./Layout/AppMain/index"
import Loader from "./DemoPages/MyComponents/loader"

import "./app.css"

const App = () => (
  <Provider store={store}>
    <PersistGate loading={Loader} persistor={persistor}>
      <AppMain />
    </PersistGate>
  </Provider>
)

export default App
