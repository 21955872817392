import React, { lazy, useEffect, useState } from "react"
import { Switch, Redirect } from "react-router-dom"
// import { ToastContainer } from "react-toastify"
import { connect } from "react-redux"
import LazyRoute from "./lazyRoute"
import PrivateRoute from "./privateRoute"
import AppHeader from "../AppHeader"
import { fetchAlertNotification } from "../../actions/vehicleAction"

const Dashboards = lazy(() => import("../../DemoPages/UserPages/Dashboards"))
const Login = lazy(() => import("../../DemoPages/UserPages/Login"))
const Reports = lazy(() => import("../../DemoPages/UserPages/Reports"))
const Settings = lazy(() => import("../../DemoPages/UserPages/Settings"))
const GeoFence = lazy(() => import("../../DemoPages/UserPages/GeoFence"))
const Users = lazy(() => import("../../DemoPages/UserPages/Users"))
const Billing = lazy(() => import("../../DemoPages/UserPages/Billing"))
const Vehicles = lazy(() => import("../../DemoPages/UserPages/Vehicles"))

const AppMain = ({ pagesAuth, fetchAlertNotification }) => {
  const [isPool, setIsPool] = useState(false)
  const [alertNotification, setAlertNotification] = useState([])

  const wait = () =>
    new Promise((resolve, reject) => setTimeout(() => resolve("relax"), 10000))

  const handleAlertNotification = async () => {
    const userId = localStorage.getItem("user_id")
    fetchAlertNotification(userId)
    await wait()
    setIsPool(!isPool)
  }

  useEffect(() => {
    if (
      !(
        window.location.pathname === "/" ||
        window.location.pathname === "/login"
      )
    )
      handleAlertNotification()
  }, [isPool])

  const renderRoute = () => (
    <Switch>
      <PrivateRoute path="/dashboard" component={Dashboards} />
      {pagesAuth["3"] && <PrivateRoute path="/reports" component={Reports} />}
      {pagesAuth["4"] && <PrivateRoute path="/setting" component={Settings} />}
      {pagesAuth["415"] && (
        <PrivateRoute path="/geofence" component={GeoFence} />
      )}
      {pagesAuth["16"] && <PrivateRoute path="/users" component={Users} />}
      {pagesAuth["182"] && <PrivateRoute path="/billing" component={Billing} />}
      {pagesAuth["17"] && (
        <PrivateRoute path="/vehicles" component={Vehicles} />
      )}
      <LazyRoute path="/login" component={Login} />
      <LazyRoute path="/" component={Login} />
      <Redirect to="/" />
    </Switch>
  )

  return (
    <>
      {localStorage.getItem("key") && (
        <AppHeader alertNotification={alertNotification} />
      )}
      {renderRoute()}
      {/* <ToastContainer /> */}
    </>
  )
}

const mapStateToProps = state => ({
  pagesAuth: state.userProfile.pagesAuth
})

export default connect(
  mapStateToProps,
  { fetchAlertNotification }
)(AppMain)
