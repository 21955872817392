import React from "react"
import styled from "styled-components"
import Icons from "../../../../../DemoPages/Icons/Examples/icons"
import gps from "../../../../../assets/images/gps.png"

const Wrapper = styled.div`
  width: 100%;
  padding-top: 2rem;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  img {
    height: 80px;
  }
`

const Card = styled.div`
  padding: 10px 20px;
  display: flex;
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  margin-top: 1.6rem;
  p {
    margin-left: 10px;
    margin-bottom: 0;
  }
`

export default ({ t }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <img src={gps} alt={gps.png} />
      </ImageWrapper>
      <Card>
        <Icons icon="user" fill="var(--success)" />
        <p>apps@nms.co.in</p>
      </Card>
      <Card>
        <Icons icon="phone" fill="var(--red)" />
        <p>+91 6565763567</p>
      </Card>
      <Card>
        <Icons icon="email" fill="var(--info)" />
        <p>apps@nms.co.in</p>
      </Card>
      <Card>
        <Icons icon="building" fill="var(--primary)" />
        <p>NMS</p>
      </Card>
      {/* <Card>
        <Icons icon="user" />
        <p>apps@nms.co.in</p>
      </Card> */}
    </Wrapper>
  )
}
