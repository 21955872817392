import React, { Suspense } from "react"
import styled from "styled-components"
import Hamburger from "react-hamburgers"
import PerfectScrollbar from "react-perfect-scrollbar"
import Drawer from "react-motion-drawer"
import RightDrawer from "./TabsContent/rightDrawer"
// import loaderSvg from "../../../assets/svgs/loader1.svg"
import Loader from "react-loaders"

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 60px;
  }
`

const MyLoader = (
  <LoaderWrapper>
    {/* <img src={loaderSvg} alt="loader.png" /> */}
    <Loader type="ball-grid-beat" />
  </LoaderWrapper>
)

const RightSideBar = ({ openRight, openLeft, onChangeOpenRight }) => (
  <Suspense fallback={<>.</>}>
    <Drawer
      right
      className="drawer-content-wrapper p-0"
      width={350}
      open={openRight}
      onChange={open => onChangeOpenRight(open)}
      noTouchOpen={false}
      noTouchClose={false}
    >
      <PerfectScrollbar>
        <div className="drawer-nav-btn drawer-nav-btn_right">
          <Hamburger
            active={openRight}
            type="elastic"
            onClick={() => onChangeOpenRight(false, false)}
          />
        </div>
        <RightDrawer />
      </PerfectScrollbar>
    </Drawer>
  </Suspense>
)

export default RightSideBar
