import React from "react"
import { DropdownItem, Popover } from "reactstrap"
import city3 from "../../../assets/utils/images/dropdown-header/city3.jpg"

export default ({ t, pagesAuth, toggle, popoverOpen, onRedirectClick }) => {
  const renderDropDownItem = (toggle, dataAttr = "us", label) => (
    <DropdownItem
      toggle={toggle}
      data-lang={dataAttr.toLowerCase()}
      onClick={() => onRedirectClick(label.toLowerCase())}
    >
      {label}
    </DropdownItem>
  )

  return (
    <Popover
      className="popover-custom popover-custom-sm"
      trigger="legacy"
      hideArrow
      fade={false}
      placement="top"
      // isOpen={isOpenPopUp ? true : false}
      // isOpen={popoverOpen ? popoverOpen : isOpenPopUp ? isOpenPopUp : false}
      isOpen={popoverOpen}
      target="PopoverFooter-2"
      toggle={toggle}
    >
      <div className="dropdown-menu-header">
        <div className="dropdown-menu-header-inner py-2 bg-dark">
          <div
            className="menu-header-image opacity-1"
            style={{
              backgroundImage: "url(" + city3 + ")"
            }}
          />
          <div className="menu-header-content text-center text-white">
            <h6 className="menu-header-subtitle mt-0">{t("CHOOSE_REPORT")}</h6>
          </div>
        </div>
      </div>
      <div className="dropdown-menu-rounded pt-0">
        {pagesAuth["76"] && renderDropDownItem(false, "us", t("ALERT"))}
        {pagesAuth["30"] &&
          renderDropDownItem(false, "ch", t("VEHICLE_HISTORY"))}
        {/* {renderDropDownItem(false, "ch", t("MAP_HISTORY"))} */}
        {pagesAuth["42"] && renderDropDownItem(false, "fr", t("TRIP"))}
        {pagesAuth["31"] &&
          renderDropDownItem(false, "es", t("VEHICLE_DISTANCE"))}
        {pagesAuth["32"] && renderDropDownItem(false, "es", t("FLEET_USAGE"))}
        {pagesAuth["45"] && renderDropDownItem(false, "es", t("OVER_SPEED"))}
        {pagesAuth["142"] && renderDropDownItem(false, "es", t("FUEL"))}
        {/* {renderDropDownItem(false, "es", t("TEMPERATURE"))} */}
        {pagesAuth["71"] && renderDropDownItem(false, "es", t("TRIP_IGNITION"))}
        {renderDropDownItem(false, "es", t("DIGITAL_SENSOR"))}
      </div>
    </Popover>
  )
}
