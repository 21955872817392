import { SET_HOSTNAME, SET_ORIGIN } from "../types/origin"

const initialState = {
  origin: null,
  hostName: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HOSTNAME:
      return {
        ...state,
        hostName: action.payload
      }
    case SET_ORIGIN:
      return {
        ...state,
        origin: action.payload
      }
    default:
      return { ...state }
  }
}
