import React, { Fragment } from "react"
import styled from "styled-components"
import Hamburger from "react-hamburgers"
import RightSideBar from "./RightSideBar"
import LeftSideBar from "./LeftSideBar"

const NotificationDiv = styled.div`
  cursor: pointer;
`

class HeaderRightDrawer extends React.Component {
  state = {
    active: false,
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 450,
    noTouchOpen: false,
    noTouchClose: false
  }

  render() {
    const { openRight, openLeft } = this.state
    const { right, left, hamBurger, children } = this.props

    return (
      <Fragment>
        {right && (
          <RightSideBar
            openRight={openRight}
            onChangeOpenRight={openRight =>
              this.setState({ openRight, openLeft: false })
            }
          />
        )}

        {left && (
          <LeftSideBar
            openLeft={openLeft}
            onChangeOpenLeft={openLeft =>
              this.setState({ openLeft, openRight: false })
            }
          />
        )}

        {/* <div className="right-header-btn-lg"> */}
        {hamBurger && (
          <div className={this.props.hamburgerClass}>
            <Hamburger
              active={
                (openRight && right === "true") || (openLeft && left === "true")
              }
              type="elastic"
              onClick={() => {
                right === "true" && this.setState({ openRight: !openRight })
                left === "true" && this.setState({ openLeft: !openLeft })
                //this.setState({ openRight: !openRight, openLeft: false })
              }}
            />
          </div>
        )}
        <NotificationDiv
          onClick={() => {
            right === "true" && this.setState({ openRight: !openRight })
            left === "true" && this.setState({ openLeft: !openLeft })
          }}
        >
          {children}
        </NotificationDiv>
      </Fragment>
    )
  }
}

export default HeaderRightDrawer
