import React from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { ListGroupItem, ListGroup } from "reactstrap"
import styled from "styled-components"
import Icons from "../../../../DemoPages/Icons/Examples/icons"

const LogoHeader = styled.div`
  width: 100%;
  background: darkcyan;
  padding: 24px 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
`

const H5 = styled.h5`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  margin: 0;
`

const DrawerBigExample = ({ alertNotification }) => {
  const { t } = useTranslation()

  return (
    <>
      <LogoHeader>
        {/* <H5 className="drawer-heading">{t("ALERT_NOTIFICATION")}</H5> */}
        <H5>{t("ALERT_NOTIFICATION")}</H5>
        <Icons icon="alert" width={36} fill="#fff" />
      </LogoHeader>
      <div className="drawer-section p-0">
        <div className="todo-box">
          <ListGroup className="todo-list-wrapper pl-1" flush>
            {alertNotification &&
              alertNotification.map((alert) => (
                <ListGroupItem key={alert.alert_id}>
                  <div className="todo-indicator bg-danger" />
                  <div className="widget-content p-0 ml-1">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading">
                          {alert.alert_type_name}
                        </div>
                        <div className="widget-subheading">
                          {alert.registration_no}
                        </div>
                      </div>

                      <div className="widget-content-right ml-3">
                        <div className="badge badge-pill badge-success">
                          {alert.alert_time}
                        </div>
                      </div>
                    </div>
                  </div>
                </ListGroupItem>
              ))}
          </ListGroup>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  alertNotification: state.alertNotification.alertNotification,
})

export default connect(mapStateToProps)(DrawerBigExample)
